import * as React from 'react';
import * as Styled from './svgWithLinkStyle';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PropanePopup from '../../components/propanePopup/propanePopup';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const SVGWithLink = ({ svgIconData, delay }) => {
  const [showPropaneModal, setShowPropaneModal] = React.useState(false);
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="m-0">{children}</p>,
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <>
      <Styled.DeliveringMoreProductListItem
        href={!showPropaneModal && isExternalUrlHref(svgIconData?.path)? svgIconData?.path :addTrailingSlash(process.env.GATSBY_DOMAIN_URL+svgIconData?.path)}
        target={isExternalUrl(svgIconData?.path) ? '_blank' : '_self'}
        data-aos="fade-up"
        data-aos-delay={delay}
        data-aos-once="true"
        data-aos-duration="1000"
        onClick={() => {
          svgIconData.title === 'Propane'
            ? setShowPropaneModal(true)
            : setShowPropaneModal(false);
        }}
      >
        <Styled.DeliveringMoreProductListImage
          src={svgIconData?.image?.file?.url}
          alt={svgIconData?.image?.title}
        />
        {svgIconData?.richText?.raw &&
          documentToReactComponents(
            JSON.parse(svgIconData?.richText?.raw),
            optionsMainStyle
          )}
      </Styled.DeliveringMoreProductListItem>
      {showPropaneModal && <PropanePopup showModal={setShowPropaneModal} />}
    </>
  );
};

export default SVGWithLink;
